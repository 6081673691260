<template>
  <div class="px-6 lg:px-16" :key="num">
    <span v-html="txt"/>
  </div>
</template>
<script>
export default {
  name: "HomepageSectionMultiContent",
  props: {
    num: String,
    txt: String,
  }
}
</script>

