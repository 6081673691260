<template>
  <div class="w-full flex justify-center items-center">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "HomepageContainer"
}
</script>