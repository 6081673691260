<template>
  <div class="pt-12 lg:pt-0">
    <div v-scroll-spy="{data: 'div.homepage-selector', offset: checkIsMobile? 120:120}">
      <!-- LOGO -->
      <div class="relative homepage-selector">
<!--        <div ref="topSection"-->
<!--          class="relative w-full h-screen flex flex-col justify-center items-center"-->
<!--        >-->
<!--          <div class="w-full flex flex-col justify-center items-center z-10">-->
<!--            <Slogan-->
<!--              class="font-light opacity-75 lg:opacity-50 pt-16 lg:pt-32"-->
<!--              text1="PAGE_TITLE:About"-->
<!--              desktop-size="2xl"-->
<!--              :mobile-divider=24-->
<!--              :uppercase=true-->
<!--              :space-separated=true-->
<!--            />-->
<!--            <Slogan-->
<!--              class="pb-2 lg:pb-6 pt-6"-->
<!--              text1="LOGO:Segment1"-->
<!--              text2="LOGO:Segment2"-->
<!--              :text2-highlighted="true"-->
<!--              text3="LOGO:Segment3"-->
<!--              desktop-size="7xl"-->
<!--              :mobile-divider=9-->
<!--            />-->
<!--            <Slogan-->
<!--              class="font-light"-->
<!--              text1="LOGO:Slogan3.1"-->
<!--              text2="LOGO:Slogan3.2"-->
<!--              :text2-highlighted="true"-->
<!--              text3="LOGO:Slogan3.3"-->
<!--              desktop-size="3xl"-->
<!--              :mobile-divider=18-->
<!--              :space-separated=true-->
<!--              :break-on-mobile=true-->
<!--            />-->
<!--          </div>-->
<!--          <HomepageChevron @click="scrollToSection('whatSection')"/>-->
<!--        </div>-->
        <div class="absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center z-50">
          <HomepageChevron large @click="scrollToSection('whatSection')"/>
        </div>
        <HomepageParallax no-top-margin-desktop no-top-margin-mobile no-bottom-margin-mobile screen-high
          :class="['relative', checkIsMobile?'parallax-tiptoes-mobile':'parallax-tiptoes']"
        />
      </div>
      <!--  WHAT? -->
      <div class="homepage-selector" ref="whatSection" id="whatSection">
        <HomepageSection>
          <template v-slot:leftTitle>{{ trans('ABOUT:What') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:WhatText')"/></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalClink"/></template>
        </HomepageSection>
        <HomepageContainer>
          <HomepageChevron bottom-margin dark @click="scrollToSection('whySection')"/>
        </HomepageContainer>
        <HomepageParallax no-top-margin-desktop no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-roses'"/>
      </div>
      <!--  WHY? -->
      <div class="homepage-selector" ref="whySection" id="whySection">
        <HomepageSection>
          <template v-slot:leftTitle>{{ trans('ABOUT:Why') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:WhyText')"/></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalTouch"/></template>
        </HomepageSection>
        <HomepageContainer>
          <HomepageChevron bottom-margin dark @click="scrollToSection('howWeSection')"/>
        </HomepageContainer>
        <HomepageParallax no-top-margin-desktop no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-table'"/>
      </div>
      <!--  HOW WE? -->
      <div class="homepage-selector" ref="howWeSection" id="howWeSection">
        <HomepageSectionMulti>
          <template v-slot:title>{{ trans('ABOUT:HowWe') }}</template>
          <template v-slot:subsections>
            <div v-for="(obj, index) in howWeData" :key="index">
              <HomepageSectionMultiTitle center no-select>
                {{ obj.title }}
              </HomepageSectionMultiTitle>
              <HomepageSectionMultiContent :num = "index" :txt = "howWeData[index].text"/>
            </div>
          </template>
        </HomepageSectionMulti>
        <HomepageContainer>
          <HomepageChevron bottom-margin dark @click="scrollToSection('howToSection')"/>
        </HomepageContainer>
        <HomepageParallax no-top-margin-desktop no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-walk'"/>
      </div>
      <!-- HOW TO?  -->
      <div class="homepage-selector" ref="howToSection" id="howToSection">
        <HomepageSectionMulti>
          <template v-slot:title>{{ trans('ABOUT:HowTo') }}</template>
          <template v-slot:subsections>
            <div v-for="(obj, index) in howToData" :key="index">
              <HomepageSectionMultiTitle center no-select>
                {{ obj.title }}
              </HomepageSectionMultiTitle>
              <HomepageSectionMultiContent :num = "index" :txt = "howToData[index].text"/>
            </div>
          </template>
        </HomepageSectionMulti>
        <HomepageContainer>
          <HomepageChevron bottom-margin dark @click="scrollToSection('authorSection')"/>
        </HomepageContainer>
        <HomepageParallax no-top-margin-desktop no-top-margin-mobile no-bottom-margin-mobile :class="'parallax-come'"/>
      </div>
      <div class="homepage-selector" ref="authorSection" id="authorSection" >
        <!-- AUTHOR 1 -->
        <HomepageSection class="pb-16 lg:pb-0">
          <template v-slot:leftTitle>{{ trans('ABOUT:Author') }}</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:AuthorText1')"/></template>
          <template v-slot:rightContent><HomepageImage :img="themeImages.aboutVerticalAuthor"/></template>
        </HomepageSection>
        <!-- AUTHOR 2 -->
        <HomepageSection reverse class="lg:pt-28">
          <template v-slot:leftTitle>...</template>
          <template v-slot:leftText><span v-html="trans('ABOUT:AuthorText2')"/></template>
          <template v-slot:rightContent><HomepageImage :img="checkIsMobile?themeImages.aboutVerticalAuthor:themeImages.aboutVerticalAuthor2" :show="true"/></template>
        </HomepageSection>
        <HomepageContainer>
          <HomepageChevron bottom-margin dark @click="scrollToSection('welcome')"/>
        </HomepageContainer>
        <HomepageParallax
          no-top-margin-desktop
          no-top-margin-mobile
          no-bottom-margin-desktop
          no-bottom-margin-mobile
          :desktop-height=600
          :class="'parallax-author'"
        />
      </div>
      <!-- WELCOME -->
      <div ref="welcome" id="welcome"
        class="homepage-selector h-screen w-full flex flex-col justify-center items-center"
      >
        <SectionWelcome/>
      </div>
    </div>
  </div>
</template>

<script>
import 'jquery-parallax.js'
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import HomepageImage from "@/views/content/components/Homepage/HomepageImage";
import Slogan from "@/views/content/components/Homepage/Slogan";
import HomepageChevron from "@/views/content/components/Homepage/HomepageChevron";
import HomepageContainer from "@/views/content/components/Homepage/HomepageContainer";
import HomepagePageTitle from "@/views/content/components/Homepage/HomepagePageTitle";
import HomepageParallax from "@/views/content/components/Homepage/HomepageParallax";
import HomepageSection from "@/views/content/components/Homepage/HomepageSection";
import HomepageSectionMulti from "@/views/content/components/Homepage/HomepageSectionMulti";
import HomepageSectionMultiContent from "@/views/content/components/Homepage/HomepageSectionMultiContent";
import HomepageSectionMultiTitle from "@/views/content/components/Homepage/HomepageSectionMultiTitle";
import TextField from "@/utils/crud/components/field-types/Text";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import themeConfig from "@/themeConfig";
import {isAppLaunched} from "@/common/helpers/utils";
import {mapGetters, mapActions} from "vuex";
import SectionWelcome from "@/views/content/static/components/SectionWelcome";

export default {
  name: "Homepage",
  components: {
    BaseIcon,
    Button,
    HomepageImage,
    Slogan,
    HomepageChevron,
    HomepageContainer,
    HomepagePageTitle,
    HomepageParallax,
    HomepageSection,
    HomepageSectionMulti,
    HomepageSectionMultiContent,
    HomepageSectionMultiTitle,
    TextField,
    SectionWelcome
  },
  data() {
    return {
      email: null,
      themeImages: themeConfig.staticPhotos,
      checkIsMobile: checkIsMobileDevice(),
      isAppLaunched: isAppLaunched(),
      howWeChosen: "1",
      howWeChosen2: "2",
      howToChosen: "1",
      howWeData: {},
      howToData: {},
    }
  },
  watch: {
    langLoaded: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.$route.query.error) {
            this.error({message: this.trans(`ALERT:${this.$route.query.error}`)})
          }
          if (this.$route.query.success) {
            this.success({message: this.trans(`ALERT:${this.$route.query.success}`)})
          }

          this.howWeData = {
            1: {
              title: this.trans('ABOUT:HowWeTitle1'),
              text: this.trans('ABOUT:HowWeText1'),
            },
            2: {
              title: this.trans('ABOUT:HowWeTitle2'),
              text: this.trans('ABOUT:HowWeText2'),
            },
            3: {
              title: this.trans('ABOUT:HowWeTitle3'),
              text: this.trans('ABOUT:HowWeText3'),
            },
            4: {
              title: this.trans('ABOUT:HowWeTitle4'),
              text: this.trans('ABOUT:HowWeText4'),
            },
            5: {
              title: this.trans('ABOUT:HowWeTitle5'),
              text: this.trans('ABOUT:HowWeText5'),
            },
            6: {
              title: this.trans('ABOUT:HowWeTitle6'),
              text: this.trans('ABOUT:HowWeText6'),
            },
          }

          this.howToData = {
            1: {
              title: this.trans('ABOUT:HowToTitle1'),
              text: this.trans('ABOUT:HowToText1'),
            },
            2: {
              title: this.trans('ABOUT:HowToTitle2'),
              text: this.trans('ABOUT:HowToText2'),
            },
            3: {
              title: this.trans('ABOUT:HowToTitle3'),
              text: this.trans('ABOUT:HowToText3'),
            },
            4: {
              title: this.trans('ABOUT:HowToTitle4'),
              text: this.trans('ABOUT:HowToText4'),
            },
            5: {
              title: this.trans('ABOUT:HowToTitle5'),
              text: this.trans('ABOUT:HowToText5'),
            },
            6: {
              title: this.trans('ABOUT:HowToTitle6'),
              text: this.trans('ABOUT:HowToText6'),
            },
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters('account', ['userData', 'authDone']),
    ...mapGetters('newsletter', ['newsletterLoading']),
    ...mapGetters('lang', ['langLoaded']),
  },
  methods: {
    ...mapActions('newsletter', ['signToNewsletterRequest']),
    ...mapActions('alert', ['error', 'success']),

    signToNewsletter() {
      this.signToNewsletterRequest({email: this.email})
    },
    scrollToSection(section) {
      let element = document.getElementById(section);
      let headerOffset = this.checkIsMobile? 120:120;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    $('.parallax-tiptoes').parallax({imageSrc: this.themeImages.aboutWideTiptoes,});
    $('.parallax-tiptoes-mobile').parallax({imageSrc: this.themeImages.aboutWideTiptoesMobile,});
    $('.parallax-author').parallax({imageSrc: this.themeImages.aboutWideAuthor});
    $('.parallax-come').parallax({imageSrc: this.themeImages.aboutWideCome});
    $('.parallax-walk').parallax({imageSrc: this.themeImages.aboutWideWalk});
    $('.parallax-roses').parallax({imageSrc: this.themeImages.aboutWideRoses});
    $('.parallax-table').parallax({imageSrc: this.themeImages.aboutWideTable});

    this.$root.$on('scroll-to-top', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    this.$root.$on('scroll-to-welcome', () => {
      const welcomeElement = document.getElementById('welcome');
      if (welcomeElement) {
        welcomeElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error('Element with id "welcome" not found.');
      }
    });
  },
  beforeDestroy() {
    $('.parallax-mirror').remove()
  }
}
</script>

<style lang="scss">
.prlx-img {
  height: 240px;
}

@media (min-width: 1024px) {
  .prlx-img {
    height: 480px;
  }
}

.menu-link {
  &.active-link {
    opacity: 1;
    color: theme('colors.redAccent')
  }
}

</style>